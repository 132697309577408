<div class="ui middle aligned divided list">
  <div *ngFor="let business of businesses" class="item">
    <div class="right floated content">
      <div class="ui button positive" (click)="getManagement(business)">Connect Pages</div>
    </div>
    <img class="ui avatar image" [src]="business.image">
    <div class="content">
      {{business.name}}
      <div class="list">
        <div *ngFor="let page of business.pages" class="item">
          <img class="ui avatar image" [src]="page.image">
          <div class="content">
            {{page.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
