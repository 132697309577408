import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services';
import {Business, Page} from '../../model';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

declare const FB: any;

@Component({
  selector: 'app-index',
  template: '',
})
export class IndexComponent implements OnInit {

  constructor(public authenticationService: AuthenticationService,
              private router: Router) {
  }

  ngOnInit(): void {
    document.location.replace('/login');
  }
}
