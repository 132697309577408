<div class="ui middle aligned center aligned grid login">
  <div class="column">
    <form class="ui large form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="ui stacked segment">
        <i class="facebook icon large"></i>
<!--        <div class="row">Facebook developer app</div>-->
        <div class="form-group" style="margin-top: 10px;">
          <button class="ui fluid large blue submit button" [ngClass]="{ 'loading': loading }">
            Connect Your Facebook Page Now
          </button>
        </div>
      </div>
    </form>
    <div *ngIf="error && !loading" class="ui error message">{{ error }}</div>
  </div>
</div>
