import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthenticationService} from '../services';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private authenticationService: AuthenticationService) {
  }

  canActivate() {
    if (this.authenticationService.userId || !this.authenticationService.informationIsGeted) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
