<div class="ui fixed inverted menu">
  <div class="ui container">
    <div class="ui simple dropdown item">
      <img class="ui logo" [src]="authenticationService.userPictureUrl">
      {{authenticationService.userName}} <i class="dropdown icon"></i>
      <div class="menu">
        <a class="item" (click)="authenticationService.FBlogout()">Log Out</a>
      </div>
    </div>
  </div>
</div>
<div class="ui main text container">
  <div class=" ui segment">
    <router-outlet></router-outlet>
  </div>
</div>
