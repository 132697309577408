import {Component, NgZone, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services';
import {Business, Page} from '../../model';

declare const FB: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  businesses: Business[] = [];

  userName = '';
  userUrl = '';

  constructor(public authenticationService: AuthenticationService, public ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.userName = this.authenticationService.userName;
      this.userUrl = this.authenticationService.userPictureUrl;
      FB.api('/me/businesses', (response) => {
        if (response && !response.error) {
          for (const business of response.data as Business[]) {
            this.businesses.push(business);
            this.getBusinessInformation(business);
          }
        }
      });
    }, 2000);
  }


  getBusinessInformation(business) {
    business.pages = [];
    FB.api('/' + business.id + '/owned_pages', (response) => {
      if (response && !response.error) {
        for (const page of response.data as Page[]) {
          business.pages.push(page);
          this.getPageInformation(page);
        }
      }
    });
    FB.api('/' + business.id + '/picture', 'GET', {redirect: 'false', width: 100, height: 100}, (response) => {
      if (response && !response.error) {
        business.image = response.data.url;
      }
    });
  }

  getPageInformation(page) {
    FB.api('/' + page.id + '/picture', 'GET', {redirect: 'false', width: 100, height: 100}, (response) => {
      if (response && !response.error) {
        page.image = response.data.url;
      }
    });
  }

  getManagement(businsess) {

    FB.api('/' + businsess.id + '/business_users',
      'POST',
      {
        email: 'erdem@internetmarketingcompany.com',
        role: 'ADMIN',
      },
      (response) => {
        console.log(response);
        console.log(response.error);
        if (response && !response.error) {
          document.location.replace('https://www.internetmarketingcompany.com/fb-connect-success');
        }
      });
  }
}
