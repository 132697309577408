import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

declare const FB: any;
declare const window: any;

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  informationIsGeted = false;

  _userName: string;
  userId: string;
  userPictureUrl: string;

  constructor(private http: HttpClient,
              private router: Router) {
    const d = document;
    const s = 'script';
    const id = 'facebook-jssdk';
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);

    window.fbAsyncInit = () => {
      FB.init({
        appId: 1272444936285050,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v6.0'
      });
      this.checkStatus(() => {
        this.getInformation(() => {
        });
      }, () => {
        this.router.navigate(['login']);
      });
    };
  }

  FBlogin() {
    FB.login(response => {
      if (response.authResponse) {
        this.getInformation(() => {
          document.location.replace('/dashboard');
        });
      } else {
        this.informationIsGeted = true;
      }
    }, {scope: 'email,business_management'});
  }

  FBlogout() {
    document.location.replace('/login');
  }


  checkStatus(success, failCallback) {
    if (typeof FB !== 'undefined') {
      FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          if (success) {
            success();
          }
        } else {
          if (failCallback) {
            failCallback();
          }
          this.informationIsGeted = true;
        }
      });
    }
  }

  getInformation(success = () => {
  }) {
    FB.api('/me', userResp => {
      this._userName = userResp.name;
      this.userId = userResp.id;
      this.informationIsGeted = true;
      FB.api('/' + this.userId + '/picture', 'GET', {redirect: 'false', width: 100, height: 100}, imageResp => {
        this.userPictureUrl = imageResp.data.url;
        success();
      });
    });
  }

  get userName() {
    return this._userName;
  }
}
