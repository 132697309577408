import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit, OnDestroy {
  checkLoginInterval: number;

  constructor(private router: Router,
              public authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    if (this.checkLoginInterval) {
      clearInterval(this.checkLoginInterval);
    }
    this.checkLoginInterval = setInterval(() => {
    }, 30000);
  }

  ngOnDestroy(): void {
    if (this.checkLoginInterval) {
      clearInterval(this.checkLoginInterval);
    }
  }

  logout() {
    this.router.navigate(['/login']);
  }
}
